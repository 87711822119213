export default {
    methods: {
        getInvoicesShutter(props, callback) {
            return {
                'invoice-list': {
                    name: 'invoice-list',
                    title: this.$t('horse.factures'),
                    cmpPath: 'components/Invoice/List',
                    noClass: true
                },
                'invoice-unpaid': {
                    name: 'invoice-unpaid',
                    title: this.$t('invoice.titre_impayes'),
                    cmpPath: 'components/Invoice/Impayes',
                    noClass: true
                },
                'invoice-trackings': {
                    name: 'invoice-trackings',
                    title: this.$t('global.tracking_emails'),
                    cmpPath: 'components/Trackings',
                    props  
                },
                'invoice-supplier-control': {
                    name: 'invoice-supplier-control',
                    title: this.$t('invoice.supplier_control'),
                    cmpPath: 'components/InvoiceSupplier/ControlList',
                    props  
                },
                'invoice-supplier-checked': {
                    name: 'invoice-supplier-checked',
                    title: this.$t('invoice.supplier_checked'),
                    cmpPath: 'components/InvoiceSupplier/CheckedList',
                    props  
                },
                'invoice-supplier-add': {
                    name: 'invoice-supplier-add',
                    title: this.$t('invoice.supplier_add'),
                    cmpPath: 'components/InvoiceSupplier/Add',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'invoice-supplier-control-form': {
                    name: 'invoice-supplier-control-form',
                    title: this.$t('invoice.supplier_control_form'),
                    cmpPath: 'components/InvoiceSupplier/ControlForm',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'ajout-tiers': {
                    name: 'ajout-tiers',
                    title: this.$t('invoice.add_supplier'),
                    cmpPath: 'components/InvoiceSupplier/AddTiers',
                    props,
                    onOk: callback
                },
                'suggestion-pappers': {
                    name: 'suggestion-pappers',
                    title: this.$t('invoice.suggestion_pappers'),
                    cmpPath: 'components/InvoiceSupplier/SuggestionPappers',
                    props,
                    onOk: callback
                },
                'invoice-supplier-duplicate-control': {
                    name: 'invoice-supplier-duplicate-control',
                    title: this.$t('invoice.supplier_duplicate_control'),
                    cmpPath: 'components/InvoiceSupplier/DuplicateControl',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'invoice-supplier-solde': {
                    name: 'invoice-supplier-solde',
                    title: this.$t('invoice.supplier_solde'),
                    cmpPath: 'components/InvoiceSupplier/Solde',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'invoice-supplier-payment': {
                    name: 'invoice-supplier-payment',
                    title: this.$t('invoice.supplier_payment'),
                    cmpPath: 'components/InvoiceSupplier/AddPayment',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'invoice-supplier-charge-account': {
                    name: 'invoice-supplier-charge-account',
                    title: this.$t('invoice.charge_account_supplier'),
                    cmpPath: 'components/InvoiceSupplier/AddChargeAccount',
                    props,
                    onOk: this.loadInvoicesSupplier
                },
                'bank-flow-list': {
                    name: 'bank-flow-list',
                    title: this.$t('invoice.bank_flow_list'),
                    cmpPath: 'components/Invoice/BankFlowList',
                    props
                },
                'assignation-flow-transaction': {
                    name: 'assignation-flow-transaction',
                    title: this.$t('invoice.assignation_transaction'),
                    cmpPath: 'components/Invoice/AssignationTransaction',
                    props,
                    onOk: this.reload
                }
            }
        },

        setupInvoiceList() {
            const shutters = this.getInvoicesShutter({})
            this.shutterPanel().open(shutters['invoice-list'])
        },

        setupInvoiceUnpaidList() {
            const shutters = this.getInvoicesShutter({})
            this.shutterPanel().open(shutters['invoice-unpaid'])
        },

        async openTrackingsShutter(invoice_id) {
            const shutters = this.getInvoicesShutter({ invoice_id })
            this.shutterPanel().open(shutters['invoice-trackings'])
        },

        setuptInvoiceSupplierControl(tiers_id = null) {
            const shutters = this.getInvoicesShutter({tiers_id})
            this.shutterPanel().open(shutters['invoice-supplier-control'])
        },

        setuptInvoiceSupplierChecked(tiers_id = null) {
            const shutters = this.getInvoicesShutter({tiers_id})
            this.shutterPanel().open(shutters['invoice-supplier-checked'])
        },

        openAddInvoiceSupplier(tiers_id) {
            const shutters = this.getInvoicesShutter({tiers_id})
            this.shutterPanel().open(shutters['invoice-supplier-add'])
        },

        setupInvoiceSupplierControlForm(invoice_supplier) {
            const shutters = this.getInvoicesShutter({invoice_supplier, edit: false})
            this.shutterPanel().open(shutters['invoice-supplier-control-form'])
        },

        setupInvoiceSupplierEdit(invoice_supplier) {
            const shutters = this.getInvoicesShutter({invoice_supplier, edit: true})
            this.shutterPanel().open(shutters['invoice-supplier-control-form'])
        },

        setupAddTier(tiers_info, callback) {
            const shutters = this.getInvoicesShutter({tiers_info}, callback)
            this.shutterPanel().open(shutters['ajout-tiers'])
        },

        setupSuggestionPappers(tiers_info, callback) {
            const shutters = this.getInvoicesShutter({tiers_info}, callback)
            this.shutterPanel().open(shutters['suggestion-pappers'])
        },

        setUpControlDuplicate(invoices) {
            const shutters = this.getInvoicesShutter({invoices})
            this.shutterPanel().open(shutters['invoice-supplier-duplicate-control'])
        },

        setUpSoldeInvoiceSupplier(invoice_supplier) {
            const shutters = this.getInvoicesShutter({invoice_supplier})
            this.shutterPanel().open(shutters['invoice-supplier-solde'])
        },

        setUpPaymentInvoiceSupplier(invoices_suppliers, open_charge = false) {
            const shutters = this.getInvoicesShutter({invoices_suppliers, open_charge})
            this.shutterPanel().open(shutters['invoice-supplier-payment'])
        },

        setupChargeAccountSupplier(invoice_supplier, open_charge = false) {
            const shutters = this.getInvoicesShutter({invoice_supplier, open_charge})
            this.shutterPanel().open(shutters['invoice-supplier-charge-account'])
        },

        setUpBankFlowList() {
            const shutters = this.getInvoicesShutter({})
            this.shutterPanel().open(shutters['bank-flow-list'])
        },

        setupAssignationFlowTransaction(bridge_transaction) {
            const shutters = this.getInvoicesShutter({bridge_transaction})
            this.shutterPanel().open(shutters['assignation-flow-transaction'])
        }
    }
}